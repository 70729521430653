import React, { useState } from "react"

export default function Searcher(props) {
  const { selectCategory, onChangeFilter, defaultValues = {} } = props
  const [searchParams, setSearchParams] = useState(defaultValues || {})

  const handleClick = event => {
    event.preventDefault()
    if (typeof onChangeFilter === "function") {
      onChangeFilter(searchParams)
    }
  }

  const renderOptions = () => {
    return selectCategory.map(item => (
      <option value={item.pk}>{item.title}</option>
    ))
  }

  const handleInput = ({ target: { value, name } }) =>
    setSearchParams(prev => {
      if (value)
        return {
          ...prev,
          [name]:
            name === "init" || name === "finish"
              ? value.split("/").reverse().join("-")
              : value,
        }
      const newState = { ...prev }
      delete newState[name]
      return newState
    })

  const handleSelect = ({ target: { value } }) => {
    setSearchParams(prev => {
      return {
        ...prev,
        category: value,
      }
    })
  }

  const renderSelect = () =>
    Array.isArray(selectCategory) && (
      <select
        key={!!selectCategory.length}
        onChange={handleSelect}
        onBlur={null}
        className="Searcher-select"
        name=""
        id="category"
        defaultValue={defaultValues.category}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option value=""></option>
        {renderOptions()}
      </select>
    )

  return (
    <div className="Searcher">
      <form onSubmit={handleClick}>
        <div className="Searcher-content">
          <label className="Searcher-label" htmlFor="text">
            Texto
          </label>
          <input
            className="Searcher-input"
            type="text"
            name="text"
            id="text"
            onChange={handleInput}
            defaultValue={defaultValues.text}
          />
        </div>
        <div className="Searcher-content">
          <label className="Searcher-label" htmlFor="category">
            Categoría
          </label>
          {/* eslint-disable-next-line jsx-a11y/no-onchange*/}
          {renderSelect()}
        </div>
        <div className="Searcher-content">
          <label className="Searcher-label" htmlFor="home">
            Inicio
          </label>
          <input
            className="Searcher-input"
            name="init"
            id="home"
            onChange={handleInput}
            type="date"
            defaultValue={defaultValues.init}
            max={new Date().toISOString().split("T")[0]}
          />
          <label className="Searcher-label ml-2" htmlFor="end">
            Fin
          </label>
          <input
            className="Searcher-input"
            name="finish"
            id="end"
            onChange={handleInput}
            type="date"
            min={searchParams.init}
            defaultValue={defaultValues.finish}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
        <button type="submit" className="Searcher-btn">
          Buscar<i class="fas fa-search"></i>
        </button>
      </form>
    </div>
  )
}
